<template>
  <div
    class="container van-safe-area-bottom"
    :style="loading ? loadingStyle : ''"
  >
    <!-- 会员头像 -->
    <van-skeleton avatar title :loading="loading" class="avatar">
      <div class="u-flex u-col-center avatar-info">
        <van-image
          class="avatar-image"
          width="1.9rem"
          height="1.9rem"
          round
          :src="userInfo.headPortrait"
          fit="cover"
        />
        <div class="avatar-detail u-flex-1">
          <div class="u-flex u-col-center">
            <h3 class="user-name u-flex-1 van-multi-ellipsis--l2">
              {{ userInfo.userName }}
            </h3>
            <van-tag
              round
              :color="typeOfMember.color"
              :text-color="typeOfMember.textColor"
              size="medium"
            >
              <div slot="default" class="u-flex u-col-center u-row-between">
                <van-image
                  width="0.28rem"
                  height="0.28rem"
                  :src="
                    require(`@/assets/images/icon_${typeOfMember.icon}.png`)
                  "
                />
                <p>{{ typeOfMember.levelName }}</p>
              </div>
            </van-tag>
          </div>
          <p v-if="userInfo.vipLevel !== 1" class="validity-period">
            {{ $t("memberShipUntil", { n: userInfo.vipEndTime }) }}
          </p>
        </div>
      </div>
    </van-skeleton>

    <!-- 充值记录 -->
    <van-skeleton
      v-if="userInfo.recordList"
      title
      :loading="loading"
      class="keywords"
    >
      <div class="keywords-info">
        <h3 class="keywords-name">{{ $t("rechargeRecord") }}</h3>
        <ul class="keywords-content">
          <li
            class="keywords-content-item"
            v-for="(item, index) in userInfo.recordList"
            :key="index"
          >
            <p class="u-flex u-col-center u-row-between item-first">
              <span>
                {{
                  item.vipLevel === 1
                    ? $t("ordinaryMember")
                    : item.vipLevel === 2
                    ? $t("goldMember")
                    : $t("diamondMembership")
                }}
              </span>
              <span>
                {{ `${$t("monthX", { n: item.timeType === 1 ? 12 : 1 })}` }}
              </span>
            </p>
            <p class="u-flex u-col-center u-row-between">
              <span>{{ $t("vipEndTime") }}</span>
              <span>{{ item.vipEndTime }}</span>
            </p>
            <p class="u-flex u-col-center u-row-between">
              <span>{{ $t("vipStartTime") }}</span>
              <span>{{ item.vipStartTime }}</span>
            </p>
          </li>
        </ul>
      </div>
    </van-skeleton>

    <template v-else>
      <EmptyTip v-if="!loading">
        <span class="empty-tip">{{ $t("vipTips") }}</span>
      </EmptyTip>
    </template>

    <!-- 取消取费 升级会员 -->
    <div class="submit-btn u-flex u-row-center" v-if="!loading">
      <template v-if="userInfo.vipLevel !== 3">
        <div class="u-flex-1 submit-btn-first" v-if="false">
          <Buttons @click.native="handleClickLeft">
            {{ $t("cancelRenewal") }}
          </Buttons>
        </div>
        <div class="u-flex-1 submit-btn-last">
          <Buttons @click.native="handleClickRight">
            {{ $t("upgradeMembership") }}
          </Buttons>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Buttons from "@/components/Buttons";
import EmptyTip from "@/components/EmptyTip";
import { getMembershipLevel } from "@/api/myMember";

export default {
  name: "myMember",
  components: { Buttons, EmptyTip },
  data() {
    return {
      userInfo: {
        headPortrait: "", // 会员图像
        userName: "", // 会员名称
        vipLevel: 1, // 会员等级1-普通会员2-金会员3-钻石会员
        recordList: [], // 续费记录数组
      },
      // 数据是否加载完毕
      loading: true,
      loadingStyle: {
        background: "#ffffff",
        paddingTop: "0.6rem",
      },
      upgradeMembershipUrl: "com.kkai.user.module.vip.VipBuyActivity", // 升级会员跳转的url链接
    };
  },
  created() {
    this.getMembershipLevel();
  },
  computed: {
    typeOfMember() {
      let memberInfo = {};
      switch (this.userInfo.vipLevel) {
        case 1:
          memberInfo.icon = "ord_member";
          memberInfo.levelName = this.$t("ordinaryMember");
          memberInfo.color = "#f5da77";
          memberInfo.textColor = "#ac863c";
          break;
        case 2:
          memberInfo.icon = "star";
          memberInfo.levelName = this.$t("goldMember");
          memberInfo.color = "#fff7c9";
          memberInfo.textColor = "#f7ac16";
          break;
        default:
          memberInfo.icon = "diamond";
          memberInfo.levelName = this.$t("diamondMembership");
          memberInfo.color = "#edfdfa";
          memberInfo.textColor = "#7cb3e1";
          break;
      }
      return memberInfo;
    },
  },
  methods: {
    // 获取会员续费列表
    getMembershipLevel() {
      getMembershipLevel().then((response) => {
        if (response.code === 200) {
          this.loading = false;
          this.userInfo = response.data;
        }
      });
    },

    // 点击升级会员按钮
    handleClickRight() {
      this.goToVipBuyActivity(this.upgradeMembershipUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #fbf7f3;
  height: 100vh;
  overflow: auto;
}
.avatar-info {
  font-size: 0.24rem;
  font-weight: 400;
  color: #515151;
  line-height: 0.34rem;
  background: #f0e9de;
  padding: 0.6rem 0.4rem;

  .avatar-image {
    margin-right: 0.3rem;
  }

  .avatar-detail {
    .van-image {
      margin-right: 0.08rem;
    }
  }

  .user-name {
    font-size: 0.4rem;
    font-weight: 500;
    color: #515151;
    margin-right: 0.16rem;
    line-height: 0.45rem;
    word-break: break-all;
  }

  .validity-period {
    margin-top: 0.1rem;
  }
}

.avatar {
  .van-skeleton__avatar {
    width: 1.32rem;
    height: 1.32rem;
  }

  .van-skeleton__title {
    width: 100%;
    height: 100%;
  }
}

.keywords-info {
  padding: 0.48rem 0.4rem 2rem;

  .keywords-name {
    font-size: 0.32rem;
    font-weight: 500;
    color: #515151;
    line-height: 0.44rem;
  }

  .keywords-content {
    font-size: 0.24rem;
    font-weight: 400;
    text-align: justify;
    color: #515151;
    margin-top: 0.3rem;
  }

  .keywords-content-item {
    background: #fefdfc;
    border-radius: 0.16rem;
    padding: 0.3rem 0.4rem;
    line-height: 0.45rem;
    margin-bottom: 0.3rem;
    box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  }

  .keywords-content-item:last-child {
    margin-bottom: 0;
  }

  .item-first {
    margin-bottom: 0.22rem;
    font-size: 0.28rem;
    font-weight: 500;
    color: #f7ac16;

    > span:last-child {
      font-size: 0.24rem;
      color: #afafaf;
    }
  }
}

.keywords {
  .van-skeleton__title {
    width: 100%;
    height: 2rem;
    margin-top: 1.2rem;
  }
}

.empty-tips {
  height: 50%;
}

.empty-tip {
  opacity: 0.6;
  font-size: 0.28rem;
  color: rgba(81, 81, 81, 0.6);
}

.submit-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fbf7f3;
  padding: 0.4rem 0.4rem;
  font-size: 0.3rem;

  > .submit-btn-first {
    margin-right: 0.3rem;

    .button-style {
      border: 0.02rem solid #ab8439;
      height: 0.96rem;
      color: #ab8439 !important;
      background: #fbf7f3 !important;
      box-shadow: none;
    }
  }

  > .submit-btn-last {
    .button-style {
      height: 0.96rem;
      border: 0.02rem solid #d1b06f;
      background: #d1b06f !important;
    }
  }
}
</style>
