import request, { baseUrl } from "@/utils/request";

/*
 *@描述: 获取会员续费信息
 *@参数: 无
 *@作者: 金涛
 *@日期: 2022-02-17 19:29:09
 */
export function getMembershipLevel() {
  return request({
    url: `${baseUrl.api}/user/vip-info`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}
